<template>
  <businessModelList @updateTabs="updateTabs"></businessModelList>
</template>
<script>
export default {
  beforeRouteLeave(to, from, next) {
    if (to.name !== "businessDetail") {
      // 业务建模路由跳转需要清空上次的条件
      // sessionStorage.removeItem(this.$route.query.templateId);
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // 线上环境从详情返回过来不会走这个钩子函数
      // 从详情返回回来需要保留上次的查询条件
      // vm.shouldGetStorageCondition = (from.name === "businessDetail"
      //     && from.query.templateId === to.query.templateId)
      //   || to.query.screen === "6";
      // if (!vm.shouldGetStorageCondition) { sessionStorage.removeItem(this.$route.query.templateId); }
      if (!(window.cutempid && window.cutempid === this?.$route?.query?.templateId)) {
        window.cutempid = undefined;
        window.selection = undefined;
      }
    });
  },
  methods: {
    updateTabs(item) {
      console.log(item);
      this.$emit("updateTabs", item);
    }
  }

};
</script>
